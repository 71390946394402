import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import { Container, Row, Col } from "react-bootstrap"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Banners from "../components/banners"
import Form from "../components/form"

const PhotovoltaicPage = () => {
  const data = useStaticQuery(graphql`
    query {
      api {
        banners(where: { sections: { slug: "photovoltaic" } }) {
          id
          link
          link_type {
            slug
          }
          sections {
            slug
          }
          image {
            url
            imageFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH, height: 800, quality: 75)
              }
            }
          }
          image_mobile {
            url
            imageFile {
              childImageSharp {
                gatsbyImageData(layout: FULL_WIDTH, height: 350, quality: 75)
              }
            }
          }
        }
      }
    }
  `)
  return (
    <Layout>
      <Seo title="Sistemas Fotovoltaicos" />
      <Banners data={data.api.banners} />
      <Container className="padding-top-lg padding-bottom-lg">
        <Row className="title half-padding-bottom">
          <Col>
            <h1>Sistemas Fotovoltaicos</h1>
          </Col>
        </Row>
        <Row className="justify-content-center text-center mb-5">
          <Col md={9}>
            <p>
              O sistema fotovoltaico é um sistema capaz de{" "}
              <strong>gerar energia elétrica através da radiação solar</strong>{" "}
              — tornando a energia renovável e limpa.
            </p>
            <p>
              A energia produzida pode ser, então, utilizada no abastecimento da
              rede elétrica em larga escala, como acontece em indústrias, e
              também pode ser gerada em escalas menores como residenciais ou
              comerciais.
            </p>
          </Col>
        </Row>
        <Row>
          <Col
            className="text-center"
            xs={12}
            md={3}
            data-sal="fade"
            data-sal-duration="500"
            data-sal-delay="300"
            data-sal-easing="ease"
          >
            <div className="bg-image-grid-wrapper">
              <StaticImage
                src="../images/elipse.png"
                height={150}
                alt=""
                placeholder="tracedSVG"
                className="mb-3 bg-image-grid"
                layout="constrained"
                formats={["auto", "webp", "avif"]}
              />
              <div className="content-grid-wrapper">
                <StaticImage
                  src="../images/icon_home.png"
                  height={80}
                  alt="Logo"
                  placeholder="tracedSVG"
                  className="mb-3"
                />
              </div>
            </div>
            <div>
              <h2 className="title-sm mt-3">Residenciais</h2>
              <p className="text-sm">
                Seja qual for o consumo de energia na sua residência, o sistema
                fotovoltaico é a solução ideal para economizar na conta de luz e
                ainda contribuir para a sustentabilidade do planeta.
              </p>
            </div>
          </Col>
          <Col
            className="text-center"
            xs={12}
            md={3}
            data-sal="fade"
            data-sal-duration="500"
            data-sal-delay="300"
            data-sal-easing="ease"
          >
            <div className="bg-image-grid-wrapper">
              <StaticImage
                src="../images/elipse.png"
                height={150}
                alt=""
                placeholder="tracedSVG"
                className="mb-3 bg-image-grid"
                layout="constrained"
                formats={["auto", "webp", "avif"]}
              />
              <div className="content-grid-wrapper">
                <StaticImage
                  src="../images/icon_warehouse.png"
                  height={80}
                  alt="Logo"
                  placeholder="tracedSVG"
                  className="mb-3"
                />
              </div>
            </div>
            <div>
              <h2 className="title-sm mt-3">Comerciais</h2>
              <p className="text-sm">
                O consumo de energia elétrica é uma das maiores despesas no
                orçamento de muitas empresas e reduzir esse custo é o sonho de
                muitos empresários. Quanto menos despesas uma empresa tem, mais
                competitiva ela será. Imagina se você pudesse zerar seu gasto
                com energia? Você pode!
              </p>
            </div>
          </Col>
          <Col
            className="text-center"
            xs={12}
            md={3}
            data-sal="fade"
            data-sal-duration="500"
            data-sal-delay="300"
            data-sal-easing="ease"
          >
            <div className="bg-image-grid-wrapper">
              <StaticImage
                src="../images/elipse.png"
                height={150}
                alt=""
                placeholder="tracedSVG"
                className="mb-3 bg-image-grid"
                layout="constrained"
                formats={["auto", "webp", "avif"]}
              />
              <div className="content-grid-wrapper">
                <StaticImage
                  src="../images/icon_barn.png"
                  height={80}
                  alt="Logo"
                  placeholder="tracedSVG"
                  className="mb-3"
                />
              </div>
            </div>
            <div>
              <h2 className="title-sm mt-3">Rurais</h2>
              <p className="text-sm">
                Energia fotovoltaica é uma solução com retorno garantido no
                campo. Sua propriedade rural pode zerar a conta de energia
                elétrica e contribuir com o meio ambiente, deixando de emitir
                toneladas de CO2 na atmosfera. Economia, preservação e
                sustentabilidade são palavras-chave.
              </p>
            </div>
          </Col>
          <Col
            className="text-center"
            xs={12}
            md={3}
            data-sal="fade"
            data-sal-duration="500"
            data-sal-delay="300"
            data-sal-easing="ease"
          >
            <div className="bg-image-grid-wrapper">
              <StaticImage
                src="../images/elipse.png"
                height={150}
                alt=""
                placeholder="tracedSVG"
                className="mb-3 bg-image-grid"
                layout="constrained"
                formats={["auto", "webp", "avif"]}
              />
              <div className="content-grid-wrapper">
                <StaticImage
                  src="../images/icon_factory.png"
                  height={80}
                  alt="Logo"
                  placeholder="tracedSVG"
                  className="mb-3"
                />
              </div>
            </div>
            <div>
              <h2 className="title-sm mt-3">Industriais</h2>
              <p className="text-sm">
                Na indústria, o gasto com o consumo de energia elétrica pode
                chegar a valores exorbitantes e representar um dos maiores
                custos dentro do orçamento de uma empresa. A energia
                fotovoltaica é um aliado valioso para reduzir custos, gerar mais
                competitividade e sustentabilidade.
              </p>
            </div>
          </Col>
        </Row>
      </Container>
      <div className="bg-light-grey features">
        <Container className="padding-top-lg padding-bottom-lg">
          <Row className="title half-padding-bottom">
            <Col>
              <h1 className="mb-2">Conheça a diferença entre os sistemas</h1>
              <h1 className="color-grey">On Grid e Off Grid</h1>
            </Col>
          </Row>
          <Row className="align-items-center">
            <Col xs={12} md={6}>
              <StaticImage
                src="../images/ongrid.png"
                alt="Logo"
                layout="fullWidth"
                placeholder="tracedSVG"
                className="mb-3"
              />
            </Col>
            <Col
              className="pl-md-3"
              xs={12}
              md={6}
              data-sal="slide-left"
              data-sal-duration="500"
              data-sal-delay="300"
              data-sal-easing="ease"
            >
              <h2 className="title-md mb-md-3">On Grid</h2>
              <p>
                Este sistema é ligado à rede elétrica, proporcionando um custo
                mais baixo de instalação e gerando créditos de compensação
                energética.
              </p>
              <p>
                Se durante o dia, o cliente está gerando e consumindo energia ao
                mesmo tempo, essa energia é utilizada diretamente. Caso haja
                geração de energia e não haja consumo no momento, a energia
                gerada é injetada na rede elétrica da concessionária e creditada
                na conta do cliente para abater do consumo dos horários onde não
                há geração, como à noite, por exemplo.
              </p>
            </Col>
          </Row>
          <Row className="align-items-center">
            <Col xs={12} md={6} className="d-md-none">
              <StaticImage
                src="../images/offgrid.png"
                alt="Logo"
                layout="fullWidth"
                placeholder="tracedSVG"
                className="mb-md-3"
              />
            </Col>
            <Col
              className="pr-md-3 text-right"
              xs={12}
              md={6}
              data-sal="slide-right"
              data-sal-duration="500"
              data-sal-delay="300"
              data-sal-easing="ease"
            >
              <h2 className="title-md mb-3">Off Grid</h2>
              <p>
                No Sistema Off Grid a geração e consumo de energia é totalmente
                desvinculada da concessionária, é um sistema autônomo.
              </p>
              <p>
                Para isso são necessárias baterias de alta capacidade que elevam
                o custo do projeto com relação ao Sistema On Grid, mas você
                ganha independência. Por exemplo, caso falte energia na
                distribuidora da sua região, você continua consumindo sua luz de
                forma independente. É muito utilizada em regiões que não possuem
                abastecimento elétrico.
              </p>
            </Col>
            <Col xs={12} md={6} className="d-none d-md-block">
              <StaticImage
                src="../images/offgrid.png"
                alt="Logo"
                layout="fullWidth"
                placeholder="tracedSVG"
                className="mb-md-3"
              />
            </Col>
          </Row>
        </Container>
      </div>
      <Container className="padding-top-lg padding-bottom-lg">
        <Row className="title half-padding-bottom text-center justify-content-center">
          <Col md={10}>
            <h1>Quero mais informações</h1>
            <p>
              Preencha seus dados para economizar até 95% na conta de energia!
              Digite seus dados abaixo e nos diga o que procura, entraremos em
              contato para realizar o seu orçamento.
            </p>
          </Col>
        </Row>
        <Row className="justify-content-center">
          <Col md={10}>
            <Form />
          </Col>
        </Row>
      </Container>
    </Layout>
  )
}

export default PhotovoltaicPage
